import React, { useState } from 'react';
import { apiURL } from './App.js';
import './Popup.css';

function UploadPopup ({ onConfirm, onCancel }) {
  const [checked, setChecked] = useState(false);
  const [title, setTitle] = useState(null);
  const [instagram, setInstagram] = useState(null);
  const [tiktok, setTiktok] = useState(null);

  return (
    <div className="popup-overlay">
      <div className="popup-container">
        <h1 className="title highlight">Upload Your Video</h1>
        <div className="popup-fields">
          <div className="popup-input">
            <label className="popup-message">Title</label>
            <input onChange={(event) => setTitle(event.target.value)}/>
          </div>
          <div className="popup-input">
            <label className="popup-message">Instagram</label>
            <div className="hbox">@<input onChange={(event) => setInstagram(event.target.value)}/></div>
          </div>
          <div className="popup-input">
            <label className="popup-message">TikTok</label>
            <div className="hbox">@<input onChange={(event) => setTiktok(event.target.value)}/></div>
          </div>
        </div>
        <br/>
        <div className="hbox" style={{alignItems: "start"}}>
        <input type="checkbox" checked={checked} onChange={(event) => 
            setChecked(event.target.checked)
          } style={{margin: "0 10px 0 0", transform: "scale(1.25)"}}/>
          <p style={{fontSize: "0.7em", alignItems: "stretch", textAlign: "left", margin: "-6px 0 0 0"}}> 
            I agree to the terms and conditions. I understand that uploading a video is final and nothing on the video page can be edited once the video is uploaded.
          </p>
        </div>
        <div className="popup-actions">
          <button onClick={onCancel} className="popup-button">Cancel</button>
          <button onClick={() => onConfirm(title, instagram, tiktok)} disabled={!checked} className="popup-button">Confirm</button>
        </div>
      </div>
    </div>
  );
};

function VideoUploader({ uploadURL, uploadID, videoID }) {
  const [videoFile, setVideoFile] = useState(null);
  const [videoPreview, setVideoPreview] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  
  const [errorMessage, setErrorMessage] = useState(null);

  // Handle video file selection
  const handleVideoChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('video/')) {
      setVideoFile(file);
      const url = URL.createObjectURL(file); // Create video preview URL
      setVideoPreview(url);
    } else {
      alert('Please select a valid video file');
    }
  };

  // Handle video upload with XMLHttpRequest for progress tracking
  const handleUpload = async (title, instagram, tiktok) => {
    if (!videoFile) return alert('No video file selected');

    const xhr = new XMLHttpRequest();
    xhr.open('PUT', uploadURL, true);
    xhr.setRequestHeader('Content-Type', videoFile.type);

    // Track upload progress
    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentComplete = (event.loaded / event.total) * 90; // don't reach 100% because still need to insert into database
        setUploadProgress(percentComplete);
      }
    };

    xhr.onload = () => {(async () => {
      // upload success
      if (xhr.status === 200) {
        console.log(title, instagram, tiktok);
        const response = await fetch(`${apiURL}/confirm-upload`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ uploadID: uploadID, videoID: videoID, title: title, instagram: instagram, tiktok: tiktok}),
        });
        // confirmation success
        if (response.status === 200) {
          setUploadProgress(100);
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else {
          setErrorMessage(await response.text());
        }
      // upload failed
      } else {
        console.error("Upload failed:", xhr.statusText);
        alert('Failed to upload video');
      }
    })();};

    xhr.onerror = () => {
      console.error("Upload error:", xhr.statusText);
      alert('Failed to upload video');
    };

    xhr.send(videoFile); // Send the file
  };

  return (<div className="page-container" style={{fontSize: "1.1em", flex: "1"}}>
    {/* Video Preview */}
    {videoPreview && (
      <video src={videoPreview} controls className="video" />
    )}

    {/* Upload Progress */}
    {uploadProgress > 0 && (
      <div className="progress-container">
        <div className="progress-bar" style={{ width: `${uploadProgress}%` }} />
        <div className="progress-value">{Math.round(uploadProgress)}%</div>
      </div>
    )}

    <div className="hbox">
      {/* Video Input */}
      {(uploadProgress === 0) && (
        <label className={"video-input " + (videoFile ? "video-input-selected": "")}>
          <input type="file" accept="video/*" onChange={handleVideoChange} />
          <label style={{pointerEvents: "none"}}>{videoFile ? "Choose Another Video" : "Choose Video"}</label>
        </label>
      )}
      {/* Upload Button */}
      {videoFile && (uploadProgress === 0) && (
        <button onClick={() => setIsPopupOpen(true)} disabled={!videoFile} className="upload-button">Upload</button>
      )}
    </div>

    {/* Error Message */}
    {errorMessage != null && (
      <p style={{color: "red"}}>{errorMessage}</p>
    )}

    {/* Popup (if open) */}
    {isPopupOpen && (<UploadPopup message={"Are you sure you want to upload this video?"}
      onConfirm={(title, instagram, tiktok) => {
        setIsPopupOpen(false);
        handleUpload(title, instagram, tiktok);
      }} onCancel={() => 
        setIsPopupOpen(false)
      }
    />)}
    <br/>
    <h1 className="title" style={{color: "white"}}>Welcome to the StuckIt <br/> Video Uploader!</h1>
    <br/>
    <strong>If you're new here, here's how it works:</strong>
    <br/>
    <h1 className="title" style={{fontSize: "180%"}}>1.</h1>
    <strong>Choose a video to attach to your sticker and click upload</strong>
    <br/>
    <h1 className="title" style={{fontSize: "180%"}}>2.</h1>
    <strong>Add title and socials (optional)</strong>
    <br/>
    <h1 className="title" style={{fontSize: "180%"}}>3.</h1>
    <strong>Anyone can scan the sticker and watch!</strong>
    <br/>
    <div style={{display: "flex", flex: "1", alignItems: "center"}}>
      <strong className="highlight" style={{fontSize: "1.2em"}}>
        Please keep in mind:<br/>
        ALL uploads are FINAL
      </strong>
    </div>
  </div>);
};

export default VideoUploader;
