import './App.css';
import { useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, NavLink, useParams, useHistory } from "react-router-dom";
import { QrReader } from "react-qr-reader";
import Hls from 'hls.js';
import VideoUploader from './Uploader';
import Shop from './Shop';

export const apiURL = "https://www.stuckit.co/api";
export const VERSION_NUMBER = "0.2.1";

function setCookie(name, value, days) {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000); // Days in milliseconds
  document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/; SameSite=Strict`;
};
function getCookie(name) {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  return match ? match[2] : null;
};

function openWindow(url) {
  window.open(url, '_blank', 'noopener,noreferrer');
}

function Error({ error }) {
  return (<div className="page-container">
    <p className="text-block">ERROR {error}</p>
    </div>);
}

function Header() {
  return (
    <div className="App-header">
      <img src="/image/logo.png" className="App-header-logo" alt="" />
      <div className="box header-links">
        <NavLink to="/shop" className="page-link" activeClassName="page-link-current">Shop</NavLink>
        <NavLink to="/" exact className="page-link" activeClassName="page-link-current">Home</NavLink>
        <NavLink to="/about" className="page-link" activeClassName="page-link-current">About</NavLink>
      </div>
    </div>
  );
}

function Footer() {
  return (
    <div className="App-footer">
      <div className="social-media-description">
        FOLLOW US FOR 10 MILLION YEARS OF GOOD LUCK!
      </div>
      <div className="hbox" style={{alignSelf: "stretch", justifyContent: "space-between"}}>
        <div className="footer-link-container">
          <a href="/shop" className="footer-page-link">SHOP</a>
          <a href="/about" className="footer-page-link">ABOUT</a>
          <a href="/contact-us" className="footer-page-link">CONTACT US</a>
          <a href="/terms-of-service" className="footer-page-link">TERMS OF SERVICE</a>
        </div>
        <div>
          <div style={{display: "flex", justifyContent: "end", margin: "5px 0 0 0"}}>
            <button className="image-button" 
            onClick={() => openWindow("https://www.instagram.com/stuckitcompany/")}>
              <img className="social-media-logo" src="/image/instagram.png" alt="Link to our Instagram page"/>
            </button>
            <button className="image-button"
            onClick={() => openWindow("https://www.tiktok.com/@stuckit.co")}>
              <img className="social-media-logo" src="/image/tiktok.png" alt="Link to our TikTok page"/>
            </button>
          </div>
          <div className="social-media-description" style={{fontSize: "0.6em", padding: "5px 0 0 0"}}>StuckIt.co website v{VERSION_NUMBER} ©2024</div>
        </div>
      </div>
    </div>
  )
}

function ScanButton() {
  const history = useHistory();
  
  return (<div>
    <button className='scan-button' onClick={() => history.push("/scan")}>SCAN</button>
  </div>);
}

function ScanPage() {
  const [readQR, setReadQR] = useState(false);
  const history = useHistory();

  const handleScan = (data) => {
    if (data) {
      console.log("QR Code Data:", data);
      if (data.text.includes("stuckit.co/video/")) {
        history.push("/video/" + data.text.slice(-11));
        setReadQR(true);
        window.location.reload();
      } else if (data.text.includes("stuckit.co")) {
        history.push("/home");
        setReadQR(true);
        window.location.reload();
      }
    }
  };

  const handleError = (err) => {
    console.error("QR Scanner Error:", err);
  };

  return (
    <div className='page'>
      <div className='page-container' style={{display: "block"}}>
        {(!readQR && <QrReader
          className="scanner"
          delay={300}
          onError={handleError}
          onResult={handleScan}
          constraints={{
            facingMode: "environment", // Force rear camera
          }}
        />)}
        <br/>
        <label className="title">Point the camera at a StuckIt sticker to see the video!</label>
      </div>
    </div>
  )
}

function HomePage() {
  return (<div className='page' style = {{fontSize: "1.1em", fontWeight: "bold", justifyContent: "space-between"}}>
    <div className='page-container'>
    <video className="video"
        playsInline
        webkit-playsinline="true"
        src="/video/about_video.mov"
        controls
        autoPlay
      />
      <h1 className="title">The Official Home of <strong className="highlight">StuckIt</strong></h1>
      <p>
        <br/>
        Welcome to StuckIt, where skateboarding meets innovation. 
        <br/><br/><br/>
        Our stickers let you share your best videos, tied to the spot where they happened. 
        It’s about connecting with your community and showcasing your style. 
        <br/><br/><br/>
        Grab a sticker, capture the moment, and <strong>make your mark</strong>.
        <br/><br/><br/>
        Want to learn more? Check out our story on the <a href="/about" style={{color: "white"}}>about page</a>.
        <br/><br/>
      </p>
    </div>
    <div className='page-container'>
      <ScanButton />
    </div>
  </div>)
}

function AboutPage() {
  return (<div className='page'>
    <div className="page-container" style={{fontSize: "0.9em"}}>
      <h1 className="title highlight">TF is StuckIt?</h1>
      <p>
        <strong className="highlight">StuckIt</strong> was created by four college students who wanted a better way to share their moments. 
        <br/><br/>Social media has become a crowded, impersonal place where videos get buried as soon as they’re posted. 
        There’s no connection to the person behind the trick, the effort it took, or the spot where it all went down. 
        <br/><br/>We think it’s time for something different. With <strong className="highlight">StuckIt</strong>, your videos aren’t just posts, they’re a part of the real world.
      </p>
      <h1 className="title highlight">Here's how it works:</h1>
      <p style={{textAlign: "left", lineHeight: "30px"}}>
        - Each sticker includes a <strong>unique QR code.</strong><br/>
        - Scan the QR code and <strong>upload your video.</strong><br/>
        - Place the sticker at <strong>the spot it happened.</strong><br/>
      </p>
      <h1 className="title highlight">The Result:</h1>
      <p>
        Now, anyone who finds your sticker can scan it and see the trick for themselves, right where it happened. Your video isn’t just online, it’s part of the space you skate in, connecting you to others in your community. 
        <br/><br/>
        With <strong className="highlight">StuckIt</strong>, it’s not about chasing views. it’s about marking your moments in a way that lasts.
      </p>
      <p style={{color: "#cccccc"}}>
        We invite and encourage feedback from you! If you have any ideas, questions, or issues, please <a href="/contact-us" style={{color: "white"}}>contact us</a>.
      </p>
    </div>
  </div>)
}

function ContactUsPage() {
  return (<div className="page">
    <div className="page-container">
      <p style={{fontSize: "1.4em"}}>
        Email us at <a href="mailto:contact@stuckit.co" style={{color:"white"}}>
        contact@stuckit.co
        </a> for any questions, concerns, or suggestions!
      </p>
    </div>
  </div>)
}

function TermsOfService() {
  const [text, setText] = useState("");

  useEffect(() => {
    (async() => {
      const response = await fetch("/terms.txt");
      if (!response.ok) {
        setText("Failed to load terms");
      } else {
        setText(await response.text());
      }
    })();
  });

  return <div className="page-container">
    <p className='text-block'>{text}</p>
  </div>
}

function VideoPlayer({ videoID, playbackID, title, instagram, tiktok, views, datePosted }) {
  const videoRef = useRef(null);
  const videoSrc = `https://stream.mux.com/${playbackID}.m3u8`;

  useEffect(() => {
    if (videoRef.current && Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(videoSrc);
      hls.attachMedia(videoRef.current);
    } else if (videoRef.current && videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
      videoRef.current.src = videoSrc;
    }
  }, [videoSrc]);

  useEffect(() => {
    if (!videoID)
      return;

    (async () => {
      const cookieName = "stuckit_watched_video_" + videoID;
      if (getCookie(cookieName) === null) {
          setCookie(cookieName, true, 1);
          await fetch(`${apiURL}/log-view/${videoID}`, {method: 'POST'});
      }
    })();
  }, [videoID]);

  return (
    <div className='page-container' style={{flexDirection: "column"}}>
      {title && (<label className="video-title">{title}</label>)}
      <video className="video"
        playsInline
        webkit-playsinline="true"
        ref={videoRef} 
        controls 
        autoPlay
      />
      <div className="box video-info">
        <label className='view-count'>SCANS: {views}</label>
        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
          <label className='video-date'>{datePosted.toLocaleDateString()}</label>
        </div>
      </div>
      {(tiktok || instagram) && (<div className="box video-info">
        <div style={{alignContent: "center"}}>
          <h1 className="title">FOLLOW ON: </h1>
        </div>
        <div className="hbox" style={{gap: "10px"}}>
          {instagram && (<button className="image-button" onClick={() => openWindow(`https://instagram.com/${instagram}`)}>
            <img className="social-media-logo" src="/image/instagram_grey.png" alt="Link to our Instagram page"/>
          </button>)}
          {tiktok && (<button className="image-button" onClick={() => openWindow(`https://tiktok.com/@${tiktok}`)}>
            <img className="social-media-logo" src="/image/tiktok_grey.png" alt="Link to our TikTok page"/>
          </button>)}
        </div>
      </div>)}
    </div>
  )
}

function VideoPage() {
  const { videoID } = useParams();
  let prevVideoID = null;
  const [data, setData] = useState({ error: null });

  useEffect(() => {
    if (prevVideoID !== videoID) {
      // eslint-disable-next-line
      prevVideoID = videoID;

      (async () => {
        const response = await fetch(`${apiURL}/get-video/${videoID}`);
        if (response.status !== 200) {
          const text = await response.text();
          setData({error: response.status + " " + text});
        } else {
          const result = await response.json();
          setData(result);
        }
      })();
    }
  }, [videoID]); // Add dependencies
  
  const insideComponent = function () {
    // not yet loaded
    if (data.error === null) {
      return <div />

    // api error
    } else if (data.error !== 200) {
      return <Error error={data.error}/>

    // no video uploaded yet
    } else if (!data.playbackID) {
      return <VideoUploader uploadURL={data.uploadURL} uploadID={data.uploadID} videoID={videoID}/>

    // video uploaded
    } else {
      return (<VideoPlayer 
        videoID={videoID} 
        playbackID={data.playbackID} 
        title={data.title} 
        instagram={data.instagram} 
        tiktok={data.tiktok} 
        views={data.views}
        datePosted={new Date(data.date)}
      />);
    }
  }();

  return (
    <div className="page">
      {insideComponent}
    </div>
  );
}

function App() {
  return (<div>
    <div className="App">
      <Router>
        {Header()}
        <Switch>
          <Route path="/home">
            <Redirect to="/" />
          </Route>
          <Route path="/about">
            <AboutPage />
          </Route>
          <Route path="/contact-us">
            <ContactUsPage />
          </Route>
          <Route path="/video/:videoID">
            <VideoPage />
          </Route>
          <Route path="/shop">
            <Shop />
          </Route>
          <Route path="/terms-of-service">
            <TermsOfService />
          </Route>
          <Route path="/scan">
            <ScanPage />
          </Route>
          <Route path="/">
            <HomePage />
          </Route>
        </Switch>
      </Router>
      {Footer()}
    </div>
  </div>);
}

export default App;
