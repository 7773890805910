import { useState } from 'react';
import Select from 'react-select';
import './Shop.css';

const options = {
  "5": "https://buy.stripe.com/7sI9CO8LW1rDbokfYY",
  "10": "https://buy.stripe.com/8wMaGS2ny9Y90JG289",
  "25": "https://buy.stripe.com/fZe8yKbY86LXgIEdQS",
  "100": "https://buy.stripe.com/fZe8yK2ny4DPfEAfZ1"
}

function DropdownOption(value, label) {
  return {"value": value, "label": (
  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
    <span>{label[0]}</span>
    <span>{label[1]}</span>
  </div>
  )};
}

const optionLabels = [
  DropdownOption("5", ["Teaser (5 pack) ", "$7"]),
  DropdownOption("10", ["Get Yo Feet Wet (10 pack) ", "$10"]),
  DropdownOption("25", ["Big Baller (25 pack) ", "$20"]),
  DropdownOption("100", ["Paint Yo City (100 pack) ", "$60"]),
]

const dropdownStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: '#353232A6',
    borderColor: '#242020',
    borderRadius: '20px',
    textShadow: 'none',
  }),
  menuList: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: '#353232A6',
    borderColor: '#242020',
    textShadow: 'none',
  }),
  placeholder: (baseStyles, state) => ({
    ...baseStyles,
    color: '#FADD07',
  }),
  singleValue: (baseStyles, state) => ({
    ...baseStyles,
    color: '#FADD07',
  }),
  indicatorSeparator: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: '#FADD07',
  }),
  dropdownIndicator: (baseStyles, state) => ({
    ...baseStyles,
    color: '#FADD07',
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    color: '#FADD07',
    backgroundColor: 'inherit'
  }),
}

function Shop() {
  const [stickerCount, setStickerCount] = useState(null);
  const [showSelectQuantityMessage, setShowSelectQuantityMessage] = useState(false);

  function handleClick () {
    if (stickerCount !== null) {
      window.open(options[stickerCount], '_blank', 'noopener,noreferrer');
    } else {
      setShowSelectQuantityMessage(true);
    }
  };

  function handleDropdownSelect (newValue) {
    setStickerCount(newValue.value);
    setShowSelectQuantityMessage(false);
  }

  return (<div className="page">
    <div className="page-container shop-container">
      <div className="product">
        <h1 className='product-title'>The OG StuckIt Sticker</h1>
        <img src="/image/product-image.png" className='product-image' alt="The Stuckit sticker"></img>
        <ul className="product-description" style={{fontSize: "0.9em"}}>
          <li className="description-bullet">3 x 3.5 INCHES</li>
          <li className="description-bullet">HIGH QUALITY MATERIALS</li>
          <li className="description-bullet">WEATHER-RESISTANT</li>
          <li className="description-bullet">CLEAN, BOLD, AND STANDS OUT</li>
          <li className="description-bullet">FREE SHIPPING</li>
        </ul>

        <Select 
          options={optionLabels} 
          onChange={handleDropdownSelect} 
          placeholder="Select quantity"
          styles={dropdownStyles}
          isSearchable={false}
        ></Select>
        <button 
          onClick={handleClick}
          className='buy-button'
        >ORDER NOW!</button>
        <br/>
        {(showSelectQuantityMessage && <strong style={{color: "red"}}><br/>Please select a quantity</strong>)}
      </div>
    </div>
  </div>);
}

export default Shop;